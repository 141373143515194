<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25"
        href="https://topolingua.com"
        target="_blank"
      >© Topolingua.com</b-link>
      <!-- <span class="d-none d-sm-inline-block">, All rights Reserved</span> -->
      <span class="ml-1">✉️ <a :href="'mailto:info@topolingua.com?subject=Need help (topolingua account '+ userData.email + ')'">info@topolingua.com</a></span>

    </span>

    <span class="float-md-right d-none d-md-block">{{ $t('handCraftedWithLove') }}
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink, BButton } from 'bootstrap-vue'

export default {
  components: {
    BLink, BButton
  },
  data() {
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
      }
    }

}
</script>



