export default [
  {
    title: 'dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'assistants',
    route: 'apps-chat',
    icon: 'MessageCircleIcon',
  },
  {
    title: 'dictionary',
    route: 'phrases-list',
    icon: 'BookmarkIcon',
  },
  {
    title: 'blog',
    route: 'blog-list',
    icon: 'BookOpenIcon',
  },
  {
    title: 'pricing',
    route: 'pages-pricing',
    icon: 'AwardIcon',
  },
  {
    title: 'profile',
    route: 'pages-profile',
    icon: 'UserIcon',
  },

]
